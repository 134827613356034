<template>
    <div class="card-video-source-section" id="card-video-source-section" v-bind:class="{ 'vue-inited': card }" ref="videoSourceSection">
        <div class="m-b-10">
            <div class="row" style="margin: 0 -10px">
                <div class="col-md-8">
                    <input class="radio-custom" id="src-file" type="radio" v-model="card.video_source_type" value="file"/>
                    <label class="radio-custom-label" for="src-file">File Upload</label>
                </div>
                <div class="col-md-4">
                    <input class="radio-custom" id="src-url" type="radio" v-model="card.video_source_type" value="url"/>
                    <label class="radio-custom-label" for="src-url">URL</label>
                </div>
            </div>
        </div>
        <div v-show="video_source_type === 'file'">
            <input type="hidden" name="card[media_video_id]" :value="media_video_id">
            <video-form holder="card" :id="media_video_id" v-on:update:media_video_id="card.media_video_id = $event"></video-form>
        </div>
        <div v-show="video_source_type === 'url'" class="card-video-source">
            <input type="hidden" name="card[video]" :value="card.video">
            <input type="hidden" name="card[embed_url_info_id]" :value="card.embed_url_info_id">
            <input type="hidden" name="card[video_poster][own_url]" :value="card.video_poster.own_url">
            <input type="hidden" name="card[video_poster][url]" :value="card.video_poster.url">
            <input type="hidden" name="card[video_source_type]" :value="card.video_source_type">

            <div class="m-b-40 form-group form-group40 group-row" style="position: relative">
                <label class="label-control">
                    <span class="label-text" style="top: 0">URL</span>
                </label>
                <input v-on:blur="onBlurVideoSourceUrl" class="form-control large_body inputs" id="source_url" placeholder="Paste your URL link here" type="text" v-model="card.video_source_url"/>
                <div class="text-right small" v-bind:class="{ 'error': video_source_error }">{{ video_source_status }}</div>
            </div>
            <div v-show="video_source_valid" class="m-b-40">
                <div v-show="card.embed_url_info.embed_content" class="card-image-section">
                    <div class="media-content">
                        <div v-if="card.video_poster.url" @click="embedAutoPlay = true" class="embed-section" v-bind:style="{ 'background-image': 'url(' + card.video_poster.url + ')' }">
                            <i class="fa fa-play-circle" v-if="!embedAutoPlay"></i>
                            <div v-if="embedAutoPlay" v-html="autoplay_embed_content"></div>
                        </div>
                        <div v-else class="embed-section" v-html="card.embed_url_info.embed_content"></div>
                    </div>
                </div>
                <div v-show="!card.embed_url_info.id">
                    <video v-if="card.video_poster.url" :poster="card.video_poster.url" :src="card.video_source_url" controls></video>
                    <video v-else :src="card.video_source_url" controls></video>
                </div>
            </div>
            <div v-show="video_source_valid" class="m-b-40 thumbnails">
                <h6>Thumbnail</h6>
                <div class="row m-b-10">
                    <div class="col-md-6">
                        <div @click="setUrlPoster(card.video_poster.own_url)" class="thumb-uploaded">
                            <div v-if="card.video_poster.url && card.video_poster.url === card.video_poster.own_url" class="fa fa-check"></div>
                            <thumbnail-dropzone2 elem_id="dropzone-card-poster-file" max_size="10"
                                                 :img_src="card.video_poster.own_url"
                                                 v-on:update:img_src="card.video_poster.own_url = $event"
                                                 :form_selector="form_selector"></thumbnail-dropzone2>
<!--                            <thumbnail>-dropzone2{'elem-id' => 'dropzone-card-poster-file', max_size: 10, ':img_src.sync' => 'card.video_poster.own_url'}</thumbnail>-->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-if="card.embed_url_info.img_src" class="dropzone-area auto-thunb">
                            <img :src="card.embed_url_info.img_src" @click="setUrlPoster('')"/>
                            <div v-if="!card.video_poster.url" class="fa fa-check"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-dont-leave></modal-dont-leave>
    </div>
</template>

<script>
    import axios from "axios";
    import VideoForm from "../../../VideoForm";
    import ModalDontLeave from "../../../ModalDontLeave"
    import ThumbnailDropzone2 from "./ThumbnailDropzone2";

    const tmpApi = {
        embed_url_info: {
            create(data) {
                return new Promise((resolve, reject) => {
                    const params = {embed_url_info: data};
                    axios.post('/embed_url_info', params)
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            console.error('Api.embed_url_info.create catch', error);
                            reject(error);
                        })
                })
            },
            show(id) {
                return new Promise((resolve, reject) => {
                    if (!id) { reject(new Error('embed_url_info id not provided')) }
                    axios.get(`/embed_url_info/${id}`)
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            console.error('Api.embed_url_info.show catch', error);
                            reject(error);
                        })
                })
            },
            fetch(id) {
                return new Promise((resolve, reject) => {
                    const interval = setInterval(function () {
                        tmpApi.embed_url_info.show(id)
                            .then(i => {
                                console.log(i.status);
                                console.log(i);
                                
                                if (i.status === 'parsed') {
                                    clearInterval(interval);
                                    resolve(i);
                                } else if (i.status === 'pending') {
                                    // console.info('Api.embed_url_info.fetch await until the url is parsed', i)
                                } else {
                                    clearInterval(interval);
                                    reject('The specified URL does not have a valid source')
                                }
                            })
                            .catch(err => {
                                clearInterval(interval);
                                reject(err)
                            });
                    }, 500)
                })
            },
        },
    }

    export default {
        components: {VideoForm, ThumbnailDropzone2, ModalDontLeave},
        props: ['_card', 'current_user_id', 'form_selector'],
        data() {
            return {
                video_source_error: false,
                video_source_valid: false,
                video_source_status: '',
                embedAutoPlay: false,
                card: {
                    embed_url_info: {},
                    video_source_type: (this._card.embed_url_info || {}).embed_content ? 'url' : 'file',
                    video_source_url: (this._card.embed_url_info || {}).url || this._card.video || '',
                    ...this._card,
                    video_poster: { ...{url: '', own_url: ''}, ...this._card.video_poster },
                }
            }
        },
        computed: {
            formSelector: function () {
                return this.form_selector
            },
            media_video_id: function () {
                return this.card.media_video_id || (this.card.uploaded_video || {}).id
            },
            video_source_type: function () {
                return this.card.video_source_type || (this.card.embed_url_info.embed_content ? 'url' : 'file')
            },
            autoplay_embed_content: function () {
                let { embed_content } = this.card.embed_url_info
                if (!embed_content) return embed_content;
                if (embed_content.includes('soundcloud.com')) {
                    return embed_content.replace('auto_play=false', 'auto_play=true');
                } else {
                    return embed_content
                        .replace(/><\/iframe>/, ' allow="autoplay;"></iframe>')
                        .replace(/(src="[^"]*)"/, function (match, p1) {
                            return (p1 + (p1.includes('?') ? '&' : '?') + 'autoplay=1"')
                        });
                }
            }
        },
        created() {
            this.onBlurVideoSourceUrl();
            if ($) {
                const form_modals = '.modal-create-card, .modal-edit-card, .modal-create-post-card, .modal-create-comment-card'
                $(form_modals).on('show.bs.modal', this.resetData)
            }
        },
        watch: {
            'card.video_poster.own_url': function (nv, ov) {
                this.card.video_poster.url = nv || '';
            },
        },

        methods: {
            resetData(card = {}) {
                Object.assign(this.$data, this.$options.data.apply(this))
            },
            setUrlPoster(url) {
                this.card.video_poster.url = url;
                this.embedAutoPlay = false;
            },
            checkVideoSource(url) {
                const vm = this;
                return new Promise((resolve, reject) => {
                    const video = document.createElement('video');
                    video.preload = 'metadata';
                    video.onloadedmetadata = function () {
                        window.URL.revokeObjectURL(video.src);
                        if (video.duration < (vm.min_duration || 10)) {
                            reject(`The video is too short (< ${vm.min_duration || 10}s)`);
                        } else if (video.videoWidth < 250 || video.videoHeight < 250) {
                            reject('Video resolution is too low (< 250px)');
                        } else {
                            resolve({
                                url,
                                duration: video.duration,
                                width: video.videoWidth,
                                height: video.videoHeight,
                            });
                        }
                    };
                    video.onerror = async function (e) {
                        try {
                            let embed_url_info = await tmpApi.embed_url_info.create({url});
                            if (embed_url_info.status !== 'parsed') {
                                embed_url_info = await tmpApi.embed_url_info.fetch(embed_url_info.id);
                            }
                            if (!embed_url_info.embed_content || ["Gaana.com", "SoundCloud", "https://sabakuch.com/music", "Spotify", "Anchor", "Jango Radio", "Spreaker"].includes(embed_url_info.provider_name)) {
                                reject('This source cannot be used for a Video card')
                            } else {
                                // vm.$root.$emit('embed_url_info_created', embed_url_info);
                                resolve({embed_url_info});
                            }
                        } catch (e) {
                            reject(e)
                        }
                    };
                    video.src = url;
                });
            },
            onBlurVideoSourceUrl() {
                this.video_source_error = false;
                this.video_source_valid = false;
                this.video_source_status = '';
                if (!this.card.video_source_url) {
                    return
                }
                this.video_source_status = 'Checking...';
                if (!this.card.video_source_url.includes('//')) {
                    this.card.video_source_url = `http://${this.card.video_source_url}`
                }

                const vm = this;
                vm.checkVideoSource(this.card.video_source_url)
                    .then(i => {
                        vm.video_source_valid = true;
                        vm.video_source_status = '';
                        const { embed_url_info, url } = i
                        if (embed_url_info) {
                            vm.card.embed_url_info_id = embed_url_info.id
                            vm.card.embed_url_info = embed_url_info
                            const formId = vm.$refs.videoSourceSection.closest('form').id
                            fillFromEmbed({formSelector: `#${formId}`, embed_url_info });
                        }
                        if (url) {
                            vm.card.video = url
                        }
                    })
                    .catch(e => {
                        vm.video_source_error = true;
                        vm.video_source_status = e;
                    })
            },
        }
    }
</script>

<style scoped>

</style>